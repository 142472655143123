var registerAddressTypeValue;

$(document).ready(function () {

});

function ActivateSelectBoxes() {
    var partIndex = $("input[name=PartIndex]").val();

    if (partIndex === "1") {
        $("#Part1_Title").select2();
        $("#Part1_UserType").select2();
    }
    if (partIndex === "2") {

    }
    if (partIndex === "3") {
        $("#Part3_JobTitle").select2();
        $("#Part3_Country").select2();
    }
};

function CreateAccountInitialiseInternationalSearchFields() {
    $("#schoolNameInputField").prop("disabled", true);
    $("#schoolNameInputDiv").hide();
    $("#rollNumberInputField").prop("disabled", true);
    $("#rollNumberInputDiv").hide();
    $("#countryInputField").prop("disabled", true);
    $("#countryInputDiv").hide();
    $("#zipCodeInputField").prop("disabled", true);
    $("#zipCodeInputDiv").hide();

    var siteUrl = $("#siteUrl").val();
    if (siteUrl.indexOf("roi") >= 0) {
        $("#schoolNameInputField").prop("disabled", false);
        $("#schoolNameInputDiv").show();
        $("#rollNumberInputField").prop("disabled", false);
        $("#rollNumberInputDiv").show();
    } else {
        $("#schoolNameInputField").prop("disabled", false);
        $("#schoolNameInputDiv").show();
        $("#zipCodeInputField").prop("disabled", false);
        $("#zipCodeInputDiv").show();
        $("#countryInputField").prop("disabled", false);
        $("#countryInputDiv").show();
    }
}

function RegisterAddressTypeChange(myRadio) {
    registerAddressTypeValue = myRadio.value;
    addressCollectionMethod = $("#Part2_AddressCollectionMethod").val();
    if (addressCollectionMethod === "OVERSEASSCHOOL") {
        if (myRadio.value === "HOME") {
            //Disable all fields, check my address not shown and click next.
            $("#schoolNameInputField").prop("disabled", true);
            $("#schoolNameInputDiv").hide(500);
            $("#rollNumberInputField").prop("disabled", true);
            $("#rollNumberInputDiv").hide(500);
            $("#countryInputField").prop("disabled", true);
            $("#countryInputDiv").hide(500);
            $("#zipCodeInputField").prop("disabled", true);
            $("#zipCodeInputDiv").hide(500);
            $("#Part2_AddressNotShown").attr("checked", true);
            $("#CreateAccountButton").click();
        } else {
            //get site url
            var siteUrl = $("#siteUrl").val();
            if (siteUrl.indexOf("roi") >= 0) {
                $("#schoolNameInputField").prop("disabled", false);
                $("#schoolNameInputDiv").show(500);
                $("#rollNumberInputField").prop("disabled", false);
                $("#rollNumberInputDiv").show(500);
            } else {
                $("#schoolNameInputField").prop("disabled", false);
                $("#schoolNameInputDiv").show(500);
                $("#zipCodeInputField").prop("disabled", false);
                $("#zipCodeInputDiv").show(500);
                $("#countryInputField").prop("disabled", false);
                $("#countryInputDiv").show(500);
            }
        }
    } else {
        if (myRadio.value === "HOME") {
            //Disable school name
            $("#schoolNameInputField").prop("disabled", true);
            $("#schoolNameInputDiv").hide(500);
        } else {
            //enable school name
            $("#schoolNameInputField").prop("disabled", false);
            $("#schoolNameInputDiv").show(500);
        }
    }

};

function TECLCreateAccount_CheckSearchKeyDown(e) {
    if (e.keyCode === 13) {
        e.preventDefault();
        //Enter Key has been pressed
        $("#AddressSearchButton").click();
    }
}

function TECLMyAddressIsNotShown(myCheckbox) {
    if (myCheckbox.checked) {
        $("#CreateAccountButton").click();
    }
};

function AddressSearch() {
    $("#AddressResult_Error").hide();
    var url = "/umbraco/Surface/WebAccountSurface/AddressSearch";
    var schoolName = $("input[name='Part2.SchoolName']").val();
    var postcode = $("input[name='Part2.Postcode']").val();
    var town = "";
    var rollNumber = $("input[name='Part2.RollNumber']").val();
    var country = $("#countryInputField").val();
    var addressCollectionMethod = $("#Part2_AddressCollectionMethod").val();
    var registerAddressType = $("#Part2_RegisterAddressType").val();
    if (registerAddressTypeValue) {
        registerAddressType = registerAddressTypeValue;
    }
    $.ajax({
        url: url,
        data: {
            schoolName: schoolName,
            postcode: postcode,
            town: town,
            rollNumber: rollNumber,
            country: country,
            registerAddressType: registerAddressType,
            addressCollectionMethod: addressCollectionMethod
        },
        dataType: 'json',
        cache: true,
        type: "POST",
        success: function (data) {

            var dropDown = $("#addressDropDown");
            var dropDownLabel = $("#addressDropDownLabel");
            if (data.Addresses.length !== 0) {
                //Populate dropdown
                dropDown.empty();
                if (data.Type === "SCHOOL") {
                    $.each(data.Addresses,
                        function (index) {
                            $(document.createElement('option'))
                                .attr('value', data.Addresses[index].Id)
                                .text(data.Addresses[index].OrgName +
                                    ', ' +
                                    data.Addresses[index].Address1 +
                                    ', ' +
                                    data.Addresses[index].Town +
                                    ', ' +
                                    data.Addresses[index].Postcode)
                                .appendTo(dropDown);
                        });
                } else {
                    $.each(data.Addresses,
                        function(index) {
                            $(document.createElement('option'))
                                .attr('value', data.Addresses[index].PostKey)
                                .text(data.Addresses[index].AddressText)
                                .appendTo(dropDown);
                        });
                }
                //unhide dropdown
                dropDownLabel.fadeIn(1000);
                dropDown.fadeIn(1000);
                //Hide no results message
                $("#AddressResult_NoResults").hide();
            } else {
                //Clear and hide dropdown
                dropDown.empty();
                dropDownLabel.hide(500);
                dropDown.hide(500);
                //Show no results message
                $("#AddressResult_NoResults").show(500);
            }
        },
        error: function (xhr, status, error) {
            console.log(xhr.responseText);
            $("#AddressResult_Error").show(500);
            alert("message : \n" +
                "An error occurred, for more info check the js console" +
                "\n status : \n" + status + " \n error : \n" + error);
        }
    });
};