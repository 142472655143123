GetFilterItems();
$(document).ready(function () {
    var loadingRipple = $("#loadingRipple");

    $("#loadMore").on("click", function (e) {

        e.preventDefault();

        $(document)
            .on({
                ajaxStart: function() {
                    loadingRipple.show();
                },
                ajaxStop: function() {
                    loadingRipple.hide();
                }
            });

        var featureElementsOnPage = $('.hero-feature-item').length;
        var url = "/umbraco/Surface/FeaturedContentSurface/LoadMoreFeaturedContent";
        $.ajax({
            url: url,
            data: {
                model: pageModel,
                alreadyLoaded: featureElementsOnPage
            },
            cache: false,
            type: "POST",
            success: function (data) {

                if (data.length !== 0) {
                    $(data.ResultString).insertBefore("#additionalFeaturedContent").hide().fadeIn(2000);
                } else {
                    data.Count = 0;
                }

                var newFeatureElementsOnPage = $('.hero-feature-item').length;
                var ajaxModelCount = data.Count - newFeatureElementsOnPage;
                if (ajaxModelCount <= 0) {
                    $("#loadMore").hide().fadeOut(2000);
                }

            },
            error: function (xhr, status, error) {
                console.log(xhr.responseText);
                alert("message : \n" +
                	"An error occurred, for more info check the js console" +
                  	"\n status : \n" + status + " \n error : \n" + error);
            }
        });
    });
});

function GetFilterItems() {
    var url = "/umbraco/Surface/FeaturedContentSurface/GetFilterItems";
    $.ajax({
        url: url,
        data: {
            documentTypes: pageModel.DocumentTypes
        },
        cache: true,
        type: "POST",
        success: function (data) {

            if (data.length !== 0) {
                $(data.ResultString).insertBefore("#filterContent").hide().fadeIn(1000);
            } else {
                data.Count = 0;
            }
        },
        error: function (xhr, status, error) {
            console.log(xhr.responseText);
            alert("message : \n" +
                "An error occurred, for more info check the js console" +
                "\n status : \n" + status + " \n error : \n" + error);
        }
    });
};

function FilterResults(e) {
    e.preventDefault();
    var featureElementsOnPage = $('.hero-feature-item').length;
    
    var items = $("#filter-form");
    var filterItemsArray = items.serializeArray();
    var filterItems = JSON.stringify(filterItemsArray);

    var url = "/umbraco/Surface/FeaturedContentSurface/FilterResults";
    $.ajax({
        url: url,
        data: {
            model: pageModel,
            filterData: filterItems,
            alreadyLoaded: featureElementsOnPage
        },
        cache: false,
        type: "POST",
        success: function (data) {

            if (data.length !== 0) {
                $(".hero-feature").fadeOut(1000);
                $(".hero-feature").remove();
                $(".toggle-filter-button").children("button").click();
                $(data.ResultString).insertBefore("#additionalFeaturedContent").hide().fadeIn(2000);
            } else {
                data.Count = 0;
            }

            var newFeatureElementsOnPage = $('.hero-feature-item').length;
            var ajaxModelCount = data.Count - newFeatureElementsOnPage;
            if (ajaxModelCount <= 0) {
                $("#loadMore").hide().fadeOut(2000);
            }

        },
        error: function (xhr, status, error) {
            console.log(xhr.responseText);
            alert("message : \n" +
                "An error occurred, for more info check the js console" +
                "\n status : \n" + status + " \n error : \n" + error);
        }
    });
};