/*----------------------------------------------------------
 The following JavaScript functions for Dates and Times
----------------------------------------------------------*/

var TECL_DateTime = {

    /// <summary>
    /// Initialise all editDateTime date and time pickers within the parent node
    /// </summary>
    /// <param name="parentNode">The DOM node within which to initialise all editDateTime date time pickers.
    /// If this is not provided then all date and time pickers on the page are initialised</param>
    InitialiseDateTimePickers: function (parentNode) {

        var dtPickersToInitialise;
        if (parentNode) {
            dtPickersToInitialise = $(parentNode).find(".editDateTimeInput");
        } else {
            dtPickersToInitialise = $(".editDateTimeInput");
        }

        if (dtPickersToInitialise) {
            dtPickersToInitialise.each(function () {
                TECL_DateTime.InitialiseDateTimePicker(this);
            });
        }
    },

    /// <summary>
    /// Sets the date time picker to the current date
    /// </summary>
    /// <param name="dtp">The date and time picker</param>
    SetCurrentDateTime: function (dtp) {
        if (dtp) {
            dtp.data("DateTimePicker").date(new Date());
        }
    },

    /// <summary>
    /// Initialise the specified date and time picker
    /// </summary>
    /// <param name="dtp">The date and time picker to initialise</param>
    InitialiseDateTimePicker: function (dtp) {
        if (dtp) {

            // If we failed to load the date time picker it is because it does not understand our locale,
            // so just use US format as all systems should be able to understand that
            $(dtp)
                .datetimepicker({
                    format: $(dtp).attr("data-format"),
                    locale: "en-gb"
                    //minDate: new Date()
        });

            // Set the date value
            var initialDate = $(dtp).attr("data-initialDate");
            if (initialDate) {
                try {
                    $(dtp).data("DateTimePicker").date(moment(initialDate, TECL_DateTime.SqlDateTimeFormat));
                } catch (ex) {
                    // Invalid date so do nothing
                }
            }
        }
    },

    /// <summary>
    /// Gets the current date and/or time from the supplied date and time picker
    /// </summary>
    /// <param name="dtp">The date and time picker to get the data from</param>
    /// <param name="locale">The locate to return the date as</param>
    /// <returns>The current date and/or time as a string</returns>
    GetDateTimePickerStringValue: function (dtp, locale) {

        var result = "";

        if (!locale) {
            locale = "en";
        }

        var jqDtp = $(dtp);

        if (jqDtp && jqDtp.length > 0) {
            var dateValue = TECL_DateTime.GetDateTimePickerValue(jqDtp, locale);
            var format = jqDtp.attr("data-format");

            switch (format.toUpperCase()) {
                case "L":
                    {
                        // Date
                        result = moment(dateValue).format(TECL_DateTime.SqlDateFormat);
                        break;
                    }
                case "HH:MM":
                    {
                        // Time
                        result = moment(dateValue).format(TECL_DateTime.SqlTimeFormat);
                        break;
                    }
                case "L HH:MM":
                    {
                        //Date and time
                        result = moment(dateValue).format(TECL_DateTime.SqlDateTimeFormat);
                        break;
                    }
            }
        }

        return result;
    },

    /// <summary>
    /// Gets the current date and/or time from the supplied date and time picker
    /// </summary>
    /// <param name="dtp">The date and time picker to get the data from</param>
    /// <param name="locale">The locate to return the date as</param>
    /// <returns>The current date and/or time as a moment date</returns>
    GetDateTimePickerValue: function (dtp, locale) {
        var result = "";

        if (!locale) {
            locale = "en";
        }

        var jqDtp = $(dtp);

        if (jqDtp && jqDtp.length > 0) {
            var dtpObject = jqDtp.data("DateTimePicker");
            if (dtpObject) {
                var dateValue = dtpObject.date();
                if (dateValue) {
                    result = dateValue.locale(locale);
                }
            }
        }

        return result;
    },

    // Enforce that the start time is before the end time if they are both valid
    EnforceStartAndEndDateRange: function (startDateCntrl, finishDateCntrl, startChanging, enforceNoNulls) {
        if (startDateCntrl && finishDateCntrl) {
            var startDate = startDateCntrl.data("DateTimePicker").date();
            var finishDate = finishDateCntrl.data("DateTimePicker").date();

            if (startDate && finishDate) {
                var isBefore = moment(startDate).isSameOrBefore(finishDate);

                if (!isBefore) {
                    if (startChanging) {
                        finishDateCntrl.data("DateTimePicker").date(startDate);
                    } else {
                        startDateCntrl.data("DateTimePicker").date(finishDate);
                    }
                }
            } else if (enforceNoNulls && finishDate == null) {
                finishDateCntrl.data("DateTimePicker").date(startDate);

            } else if (enforceNoNulls && startDate == null) {
                startDateCntrl.data("DateTimePicker").date(finishDate);
            }
        }
    }

};

// Global date and time formats
TECL_DateTime.SqlDateFormat = "YYYY-MM-DD";
TECL_DateTime.SqlTimeFormat = "HH:mm:ss";
TECL_DateTime.SqlDateTimeFormat = "YYYY-MM-DD HH:mm:ss";
TECL_DateTime.UkDateFormat = "DD/MM/YYYY";
TECL_DateTime.UkTimeFormat = "HH:mm";