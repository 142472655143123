
var TECL_Filter = {
    FilterResults: function(e, freeTextSearchTerm, numberOfColumns, pageControl) {
        e.preventDefault();
        var featureElementsOnPage = $('.hero-feature-item').length;
        var pageNumber = 1;
        var currentPage = $("#pageNumber").val();
        var currentPageNumber = parseInt(currentPage);
        if (pageControl === 'INITIAL') {
            pageNumber = 1;
        } else if (pageControl === 'NEXT') {
            //Get current page
            pageNumber = currentPageNumber + 1;
        } else if (pageControl === 'PREV') {
            pageNumber = currentPageNumber - 1;
        } else if (pageControl === 'RELOAD') {
            pageNumber = currentPageNumber;
        }

        var items = $("#filter-form");
        var filterItemsArray = items.serializeArray();
        var filterItems = JSON.stringify(filterItemsArray);

        var url = "/umbraco/Surface/SearchResultsSurface/FilterResults";
        $.ajax({
            url: url,
            data: {
                filterData: filterItems,
                freeTextSearchTerm: freeTextSearchTerm,
                numberOfColumns: numberOfColumns,
                pageNumber: pageNumber
            },
            cache: false,
            type: "POST",
            success: function (data) {
                var filterTerms = "";
                for (var i = 0; i < filterItemsArray.length; i++) {
                    var obj = filterItemsArray[i];
                    var name = obj.name;
                    var categoryAndValue = name.split(",");
                    if (name !== "DocumentType") {
                        if (filterTerms === "") {
                            filterTerms = categoryAndValue[0] + " - " + categoryAndValue[1];
                        } else {
                            filterTerms = filterTerms + ", " + categoryAndValue[0] + " - " + categoryAndValue[1];
                        }
                    }
                }
                if (filterTerms !== "") {
                    $("#filterTerms").replaceWith("<div class=\"filterTerms\" id=\"filterTerms\"><h3>Filtered by: " + filterTerms + "</h3></div>");
                } else {
                    $("#filterTerms").replaceWith("<div class=\"filterTerms\" id=\"filterTerms\"></div>");
                }
                if (data.length !== 0) {
                    var searchResults = data.SearchResults;
                    $(".searchResultItems").replaceWith(searchResults);
                    //$(".sliderProperty").slider();

                    //set the new page number
                    $("#pageNumber").val(data.PageNumber);

                    //set the pagination buttons
                    if (data.PageNumber <= 1) {
                        $("#PrevPage").hide();
                    } else {
                        $("#PrevPage").show();
                    }
                    if (data.MoreResultsAvailable) {
                        $("#NextPage").show();
                    } else {
                        $("#NextPage").hide();
                    }
                    //scroll to top of search results.
                    $(".searchResults").get(0).scrollIntoView();
                } else {
                    data.Count = 0;
                }
            },
            error: function(xhr, status, error) {
                console.log(xhr.responseText);
                alert("message : \n" +
                    "An error occurred, for more info check the js console" +
                    "\n status : \n" +
                    status +
                    " \n error : \n" +
                    error);
            }
        });
    }
    ,
    SaveSearch: function (e, freeTextSearchTerm) {
        e.preventDefault();
        var items = $("#filter-form");
        var filterItemsArray = items.serializeArray();
        var filterItems = JSON.stringify(filterItemsArray);
        var currentPageUrl = window.location.href;

        var url = "/umbraco/Surface/SearchResultsSurface/SaveSearchResults";
        $.ajax({
            url: url,
            data: {
                filterData: filterItems,
                freeTextSearchTerm: freeTextSearchTerm,
                currentPageUrl: currentPageUrl
            },
            cache: false,
            type: "POST",
            success: function (data) {

                if (data.length !== 0) {
                    var message = "";
                    if (data.Error !== undefined) {
                        message = data.Error;
                    } else {
                        message = "You can access your saved search here: ";
                    }

                    var clipboard = new Clipboard("#copyToClipboardButton");
                    clipboard.on('success', function (e) {
                        $("#copySuccessMessage").text("Copied to clipboard.");
                        e.clearSelection();
                    });

                    clipboard.on('error', function (e) {
                        console.error('Action:', e.action);
                        console.error('Trigger:', e.trigger);
                        $("#copySuccessMessage").text("Use Ctrl-C to copy the Url.");
                    });

                    $("#savedSearchMessage").text(message);
                    $("#savedSearchUrl").attr("href", data.SearchUrl);
                    $("#savedSearchUrl").text(data.SearchUrl);
                    $("#savedSearchUrlInput").val(data.SearchUrl);
                    $("#savedSearchModal").modal('show');
                } 
            },
            error: function (xhr, status, error) {
                console.log(xhr.responseText);
                alert("message : \n" +
                    "An error occurred, for more info check the js console" +
                    "\n status : \n" +
                    status +
                    " \n error : \n" +
                    error);
            }
        });
    }
}