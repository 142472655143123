// -----------------------------------------------------------------
// JavaScript for the Search Bar Component
// -----------------------------------------------------------------

var TECL_Hamburger = {
    InitialiseNavigation: function() {
        $(".site__toggle-hamburger")
            .unbind().click(function() {
                TECL_Hamburger.ToggleNavigationMenu(this);
            });
    },
    ToggleNavigationMenu: function(hamburger) {
        $("html").toggleClass("menu-open");
    }
}

var TECL_SearchBar = {

    // Initialise all search bars on the page
    InitialiseSearchBars: function () {
        $(".searchListItems-dropDownItem").unbind().click(function () {
            TECL_SearchBar.ToggleDropDown(this);
        });

        $(".searchListItems-dropDownSearch").unbind().click(function () {
            TECL_SearchBar.RunSearch(this);
        });

        $(".searchListItems-FreeTextSearch").unbind().click(function () {
            TECL_SearchBar.RunFreeTextSearch(this);
        });

        $(".searchText").keydown(function (e) {
            if (e.keyCode == 13) {
                //Enter Key has been pressed
                TECL_SearchBar.RunFreeTextSearch(this);
            }
        });

        $(".searchTab").unbind().click(function () {
            TECL_SearchBar.SelectTab(this);
        });

        TECL_SearchBar.SelectActiveTab();
    },

    // Find the active tab on each search bar and select it (it will have the attribute data-tabselected set to True)
    SelectActiveTab: function () {

        $(".searchBar").find("[data-tabSelected='True']").each(function () {
            TECL_SearchBar.SelectTab(this);
        });
    },

    // Event raised when a tab button is clicked
    SelectTab: function (tabButton) {

        if (tabButton) {

            // Get the search type for this button
            var searchTypeId = tabButton.getAttribute("data-searchTypeId");

            // Get the collection of search tabs associated with the tab group the button belongs to
            var searchTabs = $(tabButton).closest(".searchBar").children(".searchTabContents");

            if (searchTabs) {
                // Hide all tabs but the tab we want to show
                searchTabs.children(".searchTabContent").css("display", "none");
                searchTabs.children("[data-searchTypeId='" + searchTypeId + "']").css("display", "block");

                // Mark all buttons but the one clicked as being selected
                $(tabButton).siblings(".searchTab").attr("data-tabSelected", "False").removeClass("selected");
                $(tabButton).attr("data-tabSelected", "True").addClass("selected");

                // Close all drop downs that may be open for tabs
                TECL_SearchBar.CloseAllDropDowns();
            }
        }
    },

    // Toggle the drop down that is displayed
    ToggleDropDown: function (dropDown) {

        if (dropDown) {

            // Get the left hand position of the drop down
            var leftPos = dropDown.offsetLeft;
            var width = dropDown.offsetWidth;

            // Toggle the drop down items list
            var searchListItems = $(dropDown).closest(".searchListItems-dropDown").siblings(".searchListItems");
            if (searchListItems) {

                // If the drop down is already open then close it
                if (dropDown.getAttribute("data-dropDownOpen") === "true") {
                    TECL_SearchBar.CloseAllDropDowns();
                } else {

                    // Get data to pass to the server to query the database for then contents of this menu
                    var searchItemData = TECL_SearchBar.SearialiseSearchItems(dropDown);

                    $.ajax({
                        url: "/umbraco/Surface/SearchBarSurface/GetSearchItems",
                        type: "POST",
                        data: {
                            searchTypeId: $(dropDown).closest(".searchListItems-dropDown").siblings(".searchTypeId").val(),
                            componentNo: searchItemData.ComponentId,
                            component1: searchItemData.Component1,
                            component2: searchItemData.Component2,
                            component3: searchItemData.Component3,
                            component4: searchItemData.Component4
                        },

                        success: function (saveResult) {
                            if (saveResult) {
                                // Add the drop down list to the page
                                searchListItems.replaceWith(saveResult);

                                // As search list items have been replaced then we need to reselect
                                // them as our original selector will be invalid
                                searchListItems = $(dropDown).closest(".searchListItems-dropDown").siblings(".searchListItems");

                                // Hook up the events to the drop down
                                searchListItems.find(".searchItem").unbind().click(function () {
                                    TECL_SearchBar.DropDownItemSelected(this, dropDown);
                                });

                                // Close all currently open drop downs
                                TECL_SearchBar.CloseAllDropDowns();

                                // Display this drop down
                                searchListItems.removeClass("hiddenBlock");
                                searchListItems.css("left", leftPos);
                                searchListItems.css("width", width);

                                dropDown.setAttribute("data-dropDownOpen", "true");
                                //Flip the chevron icon when the dropDown is opened.
                                $(dropDown).children(".fa-chevron-down").addClass("fa-rotate-180");

                                // Increment the search drop down colours
                                var searchItemChildren = searchListItems.children("li");
                                TECL_Colourise.Colourise(searchItemChildren, 0xffffff, 0x959595);

                            }
                        }
                    });
                }
            }
        }
    },

    // Return an object containing data for the supplied drop down
    SearialiseSearchItems: function (dropDown) {

        var searchItemData = undefined;

        if (dropDown) {

            // Get the ID of the drop down that we want to display
            var dropDownId = dropDown.getAttribute("data-componentId");

            searchItemData = { "ComponentId": dropDownId };

            // Loop through all the prior drop downs and get the selected item
            $(dropDown).closest(".searchListItems-dropDown").children("li").each(function () {
                var currentDropDownId = this.getAttribute("data-componentId");

                // If this is the drop down that we clicked on then stop here
                if (currentDropDownId === dropDownId) {
                    return false;
                }

                // Add the selected item for this drop down to our search data
                searchItemData["Component" + currentDropDownId] = this.getAttribute("data-selectedItem");
            });
        }

        return searchItemData;
    },

    // Close all drop downs
    CloseAllDropDowns: function () {
        $(".searchListItems").addClass("hiddenBlock");
        $(".searchListItems-dropDownItem").attr("data-dropDownOpen", "false");
        //Reset any flipped chevron icons.
        $(".fa-chevron-down").removeClass("fa-rotate-180");
    },

    // The event raised when a drop down item is selected
    DropDownItemSelected: function (dropDownItem, dropDown) {

        TECL_SearchBar.CloseAllDropDowns();

        if (dropDown && dropDownItem) {

            // Get the selected value of the drop down item
            var selectedValue = dropDownItem.getAttribute("data-searchItemValue");

            // Update the selected value for this drop down
            dropDown.setAttribute("data-selectedItem", selectedValue);
            $(dropDown).children(".selectedItemText").html(selectedValue);

            //hide the initial text
            $(dropDown).children(".componentDescription").hide();

            // Get the ID of the drop down
            var dropDownId = parseInt(dropDown.getAttribute("data-componentId"), 10);

            // Make sure all drop downs after this one are hidden with no data selected
            $(dropDown).closest(".searchListItems-dropDown").children("li").each(function () {
                var currentDropDownId = parseInt(this.getAttribute("data-componentId"), 10);

                if (currentDropDownId > dropDownId) {
                    if (currentDropDownId === (dropDownId + 1)) {
                        $(this).addClass("inlineBlock").removeClass("hiddenBlock");
                    } else {
                        $(this).removeClass("inlineBlock").addClass("hiddenBlock");
                    }
                    this.setAttribute("data-selectedItem", "");
                    $(this).children(".selectedItemText").html("...");
                }
            });

            var tabData = $(dropDown).closest(".searchListItems-dropDown").siblings(".tabData")[0];

            if (tabData) {
                var finalDropDownId = parseInt(tabData.getAttribute("data-finalDropDownId"), 10);

                // If the current drop down is the final component, then update the final selected search item ID
                if (finalDropDownId === dropDownId) {
                    tabData.setAttribute("data-selectedSearchItemId", dropDownItem.getAttribute("data-searchItemId"));
                    //This is the final drop down item.  Automatically trigger the search
                    var searchButton = $(dropDown).closest(".searchListItems-dropDown").siblings(".searchListItems-dropDownSearch")[0];
                    TECL_SearchBar.RunSearch(searchButton);
                } else {
                    tabData.setAttribute("data-selectedSearchItemId", "");
                }
            }
        }
    },

    // Run a search
    RunSearch: function (searchButton) {

        if (searchButton) {

            // Get the selected search item ID associated with this button
            var tabData = $(searchButton).siblings(".tabData")[0];

            if (tabData) {

                var searchItemId = tabData.getAttribute("data-selectedSearchItemId");
                var searchUrl = $(tabData).closest(".searchBar").children(".searchUrl").val();
                var searchTypeId = $(tabData).closest(".searchTabContent").children(".searchTypeId").val();

                if (searchItemId && searchUrl) {
                    window.location.href = "/umbraco/Surface/SearchBarSurface/RunSearchId?searchItemId=" + searchItemId + "&searchUrl=" + searchUrl + "&searchTypeId=" + searchTypeId;
                }
            }
        }
    },

    // Run a free text search
    RunFreeTextSearch: function (searchButton) {

        if (searchButton) {

            // Get the selected search item ID associated with this button
            var searchText = $(".searchText");
            var searchUrl = $(".searchUrl").val();

            if (searchText.val() && searchUrl) {
                window.location.href = "/umbraco/Surface/SearchBarSurface/RunFreeTextSearch?searchText=" +
                    searchText.val() +
                    "&searchUrl=" +
                    searchUrl;
            }
        }
    }
};

// -----------------------------------------------------------------
// JavaScript for colouring the Search dropdowns
// -----------------------------------------------------------------
var TECL_Colourise = {

    // Set the background colour of a series of items to be a graduation between the start and end colours
    Colourise: function (items, startColor, endColor) {
        var r0 = (startColor & 0xff0000) >> 16;
        var g0 = (startColor & 0x00ff00) >> 8;
        var b0 = (startColor & 0x0000ff);
        var r1 = (endColor & 0xff0000) >> 16;
        var g1 = (endColor & 0x00ff00) >> 8;
        var b1 = (endColor & 0x0000ff);
        var steps = items.length;

        items.each(function (index) {
            var r = TECL_Colourise.Interpolate(r0, r1, index, steps);
            var g = TECL_Colourise.Interpolate(g0, g1, index, steps);
            var b = TECL_Colourise.Interpolate(b0, b1, index, steps);
            var newColor = TECL_Colourise.ZeroFill(((((r << 8) | g) << 8) | b).toString(16), 6);

            $(this).css('background-color', "#" + newColor);
        });
    },

    Interpolate: function (start, end, index, steps) {
        if (start < end) {
            return ((end - start) * (index / steps)) + start;
        }
        return ((start - end) * (1 - (index / steps))) + end;
    },

    // stolen outright from: http://stackoverflow.com/questions/1267283/how-can-i-create-a-zerofilled-value-using-javascript
    ZeroFill: function (number, width) {
        width -= number.toString().length;
        if (width > 0) {
            return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
        }
        return number;
    }
}


// Animation
// hide our element on page load
var TECL_Animation = {

    InitialiseAnimations: function () {
        //Call check_if_in_view on document load to play animations on elements that are already visible
        check_if_in_view();
    }
}