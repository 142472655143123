/*---------------------------------------------------------------------------
 The following JavaScript functions control Tender wizard client side functions
-----------------------------------------------------------------------------*/

var TECL_TenderGeneral = {

    // Close the accordian panel
    InitialiseAccordionControls: function() {

        $(".collapse").on('show.bs.collapse', function() {
            var parentNode = $(this).parent();
            parentNode.find(".panel-heading").addClass("opened");
            parentNode.find(".accordionClose").removeClass("hidden");
            parentNode.find(".accordionOpen").addClass("hidden");
        });


        $(".collapse").on('hide.bs.collapse', function() {
            var parentNode = $(this).parent();
            parentNode.find(".panel-heading").removeClass("opened");
            parentNode.find(".accordionClose").addClass("hidden");
            parentNode.find(".accordionOpen").removeClass("hidden");
        });
    },

    // Update the progress bar to the new percentage
    UpdateProgressBar: function(currentStageNumber) {

        // Update the progress bar links
        var highestStageNumber = parseInt($("#highestStageNo").val());

        if (highestStageNumber === 0 || highestStageNumber > 4) {
            // Product Selection
            TECL_TenderGeneral.ShowTitleLink(".productSelection", true);
            TECL_TenderGeneral.ShowTitleLink(".quoteDuration", false);
            TECL_TenderGeneral.ShowTitleLink(".selectSuppliers", false);
            TECL_TenderGeneral.ShowTitleLink(".userInformation", false);

        } else if (highestStageNumber === 1) {
            // Quote Duration
            TECL_TenderGeneral.ShowTitleLink(".productSelection", true);
            TECL_TenderGeneral.ShowTitleLink(".quoteDuration", true);
            TECL_TenderGeneral.ShowTitleLink(".selectSuppliers", false);
            TECL_TenderGeneral.ShowTitleLink(".userInformation", false);

        } else if (highestStageNumber === 2) {
            // Select Supplier
            TECL_TenderGeneral.ShowTitleLink(".productSelection", true);
            TECL_TenderGeneral.ShowTitleLink(".quoteDuration", true);
            TECL_TenderGeneral.ShowTitleLink(".selectSuppliers", true);
            TECL_TenderGeneral.ShowTitleLink(".userInformation", false);

        } else if (highestStageNumber === 3) {
            // User Information
            TECL_TenderGeneral.ShowTitleLink(".productSelection", true);
            TECL_TenderGeneral.ShowTitleLink(".quoteDuration", true);
            TECL_TenderGeneral.ShowTitleLink(".selectSuppliers", true);
            TECL_TenderGeneral.ShowTitleLink(".userInformation", true);

        } else {
            // Completion Page
            TECL_TenderGeneral.ShowTitleLink(".productSelection", false);
            TECL_TenderGeneral.ShowTitleLink(".quoteDuration", false);
            TECL_TenderGeneral.ShowTitleLink(".selectSuppliers", false);
            TECL_TenderGeneral.ShowTitleLink(".userInformation", false);
        }

        // Set the progress alng the bar
        var percentageComplete;

        if (currentStageNumber === 0 || currentStageNumber > 4) {
            // Product Selection
            percentageComplete = "10%";
            TECL_TenderGeneral.ShowTitleLink(".productSelection", false);

        } else if (currentStageNumber === 1) {
            // Quote Duration
            percentageComplete = "30%";
            TECL_TenderGeneral.ShowTitleLink(".quoteDuration", false);

        } else if (currentStageNumber === 2) {
            // Select Supplier
            percentageComplete = "55%";
            TECL_TenderGeneral.ShowTitleLink(".selectSuppliers", false);

        } else if (currentStageNumber === 3) {
            // User Information
            percentageComplete = "80%";
            TECL_TenderGeneral.ShowTitleLink(".userInformation", false);

        } else {
            // Completion Page
            percentageComplete = "100%";
        }

        // Update Progress bar 
        var widthText = percentageComplete;
        $("#tenderProgressBar").css("width", widthText);

        var labelText = percentageComplete + " Complete";
        $("#progress-bar-completionText").html(labelText);

    },

    // Define what title links to show
    ShowTitleLink: function(headerDivText, showLink) {
        if (showLink) {
            $(headerDivText).find(".progressBarLink").removeClass("hidden");
            $(headerDivText).find(".progressBarTitle").addClass("hidden");
        } else {
            $(headerDivText).find(".progressBarLink").addClass("hidden");
            $(headerDivText).find(".progressBarTitle").removeClass("hidden");
        }
    },

    // Show error dialog
    ShowErrorDialog: function(errorMessage) {
        $("#errorText").html(errorMessage);
        $("#errorDialog").modal("show");
    },

    // Continue to the a set stage
    ContinueToStage: function(stageNumber) {
        var cacheId = $("#cacheId").val();

        if (TECL_Master.AddLock("ContinueToStage")) {
            $.ajax({
                url: baseSiteUrl + "umbraco/Surface/Tender/ContinueToStage",
                type: "POST",
                data: {
                    cacheId: cacheId,
                    stageNumber: stageNumber
                },
                error: function(exc) {
                    TECL_Master.ClearLock("ContinueToStage");
                },
                success: function(partialViewHtml) {
                    TECL_Master.ClearLock("ContinueToStage");
                    if (partialViewHtml) {
                        $("#tenderPage").html(partialViewHtml);
                    }
                }
            });
        }
    }

};

var TECL_TenderProductsSelection = {

    // Initialise the Tenders Products Selection page
    Initialise: function() {

        // Select the first category by default
        var firstButton = $(".tenderProductCategoryButton").first();
        var categoryId = $(firstButton).attr("data-id");
        TECL_TenderProductsSelection.ChangeSelectedSection(categoryId);

        TECL_TenderGeneral.InitialiseAccordionControls();

        window.scrollTo(0, 0);

        TECL_TenderProductsSelection.UpdateProductListHeader();

        // Update the progress bar
        TECL_TenderGeneral.UpdateProgressBar(0);
    },

    // Select the first category button and section
    ChangeSelectedSection: function(categoryId) {
        $(".tenderProductCategoryButton")
            .each(function() {
                var catId = $(this).attr("data-id");
                if (catId === categoryId) {
                    $(this).addClass("selectedButton");
                } else {
                    $(this).removeClass("selectedButton");
                }
            });

        $(".tenderProductCategorySection")
            .each(function() {
                var catId = $(this).attr("data-id");
                if (catId === categoryId) {
                    $(this).removeClass("hidden");

                    // If there's only on sub category open it
                    var subCategories = $(this).find(".panel-collapse");
                    if (subCategories.length === 1) {
                        $(subCategories).collapse("show");
                    }

                } else {
                    $(this).addClass("hidden");
                }
            });
    },

    // Continue to the next stage
    ContinueToNextStage: function() {
        if (TECL_TenderProductsSelection.ValidatePage()) {
            var cacheId = $("#cacheId").val();
            var tenderName = $("#tenderName").val();

            if (TECL_Master.AddLock("ContinueNextStageQuoteDuration")) {
                // Call Server to add the product request to the cache and reload the product request list
                $.ajax({
                    url: baseSiteUrl + "umbraco/Surface/Tender/ContinueNextStageQuoteDuration",
                    type: "POST",
                    data: {
                        cacheId: cacheId,
                        tenderName: tenderName
                    },
                    error: function(exc) {
                        TECL_Master.ClearLock("ContinueNextStageQuoteDuration");
                    },
                    success: function(partialViewHtml) {
                        TECL_Master.ClearLock("ContinueNextStageQuoteDuration");
                        if (partialViewHtml) {
                            $("#tenderPage").html(partialViewHtml);
                        }
                    }
                });
            }
        }
    },

    // Vaidate the page
    ValidatePage: function() {
        $("#errTenderName").addClass("hidden");
        $("#errProductRequests").addClass("hidden");

        var noErrors = true;

        var tenderName = $("#tenderName").val();
        if (!tenderName) {
            noErrors = false;
            $("#errTenderName").removeClass("hidden");
        }

        var childItems = $(".TenderProductRequestItems").children(".TenderProductRequestSubCategory");
        if (!childItems || childItems.length === 0) {
            noErrors = false;
            $("#errProductRequests").removeClass("hidden");
        }

        return noErrors;
    },

    // Handle the adding of a product request
    AddProductRequestToItemsList: function(parentControlId) {

        var parentDiv = $(parentControlId);
        parentDiv.find(".errProductId").addClass("hidden");
        parentDiv.find(".errQuantity").addClass("hidden");
        var dropdownListId = parentDiv.find("[name='ddlProductList']").attr("id");

        var cacheId = $("#cacheId").val();

        var quantityCntrl = parentDiv.find(".valQuantity");

        var valProductId = $("#" + dropdownListId).val();
        var valProductName = $("#" + dropdownListId).data("kendoDropDownList").text();
        var valBudget = parentDiv.find(".valBudget").val();
        var valNotes = parentDiv.find(".valNotes").val();
        var containerId = parentDiv.find(".valCategoryId").val();
        var tagId = parentDiv.find(".valSubCategoryId").val();
        var tagName = parentDiv.find(".valSubCategoryName").val();

        var valQuantity;
        if (quantityCntrl.length > 0) {
            valQuantity = $(quantityCntrl).val();
        } else {
            valQuantity = 1;
        }

        var noErrors = true;

        if (!valProductId) {
            noErrors = false;
            parentDiv.find(".errProductId").removeClass("hidden");
        }

        if (!valQuantity || valQuantity <= 0) {
            noErrors = false;
            parentDiv.find(".errQuantity").removeClass("hidden");
        }

        if (noErrors) {
            TECL_TenderProductsSelection.DisplayItemAddedDlg(true);

            if (TECL_Master.AddLock("AddEditProductRequest")) {
                var productRequest = {
                    CacheId: cacheId,
                    ProductId: valProductId,
                    ProductName: valProductName,
                    Quantity: valQuantity,
                    Budget: valBudget,
                    Notes: valNotes,
                    ContainerId: containerId,
                    TagId: tagId,
                    TagName: tagName
                }

                TECL_TenderProductsSelection.AddEditProductRequest(productRequest, true);
            }
        }
    },

    //  Populate and open the edit product request dialog
    OpenProductRequestEditDlg: function(parentControlId) {

        $("#editProductRequestDlgErrQuantity").addClass("hidden");

        var parentDiv = $("#" + parentControlId);
        var valProductId = parentDiv.find(".TenderProductRequestItemProductId").val();
        var valProductName = parentDiv.find(".TenderProductRequestItemProductName").val();
        var valQuantity = parentDiv.find(".TenderProductRequestItemQuantity").val();
        var valBudget = parentDiv.find(".TenderProductRequestItemBudget").val();
        var valNotes = parentDiv.find(".TenderProductRequestItemNotes").val();
        var valContainerId = parentDiv.find(".TenderProductRequestItemContainerId").val();
        var valTagId = parentDiv.find(".TenderProductRequestItemTagId").val();
        var valTagName = parentDiv.find(".TenderProductRequestItemTagName").val();

        $("#editProductRequestDlgProductId").val(valProductId);
        $("#editProductRequestDlgProductName").text(valProductName);

        $("#editProductRequestDlgBudget").val(valBudget);
        $("#editProductRequestDlgNotes").val(valNotes);
        $("#editProductRequestDlgCategoryId").val(valContainerId);
        $("#editProductRequestDlgSubCategoryId").val(valTagId);
        $("#editProductRequestDlgSubCategoryName").val(valTagName);

        var displayProductQuantity = $("#displayProductQuantityCntrl").val();

        if (displayProductQuantity === "true" || displayProductQuantity === "True") {
            $(".editProductRequestDlgQuantitySection").removeClass("hidden");
            $("#editProductRequestDlgQuantity").val(valQuantity);
        } else {
            $(".editProductRequestDlgQuantitySection").addClass("hidden");
        }

        // Show the modal dialog but make it synchronous
        $("#tenderProductRequestEditDlg")
            .modal({
                backdrop: 'static',
                keyboard: false
            });
        $("#tenderProductRequestEditDlg").modal("show");
    },

    // Delete a product request
    DeleteProductRequest: function(productId) {
        var cacheId = $("#cacheId").val();
        if (TECL_Master.AddLock("DeleteProductRequest")) {
            // Call Server to add the product request to the cache and reload the product request list
            $.ajax({
                url: baseSiteUrl + "umbraco/Surface/Tender/DeleteProductRequest",
                type: "POST",
                data: {
                    cacheId: cacheId,
                    productId: productId
                },
                error: function(exc) {
                    TECL_Master.ClearLock("DeleteProductRequest");
                },
                success: function(productRequestHtml) {
                    TECL_Master.ClearLock("DeleteProductRequest");
                    if (productRequestHtml) {
                        $("#ProductRequests").empty().html(productRequestHtml);
                    }

                    TECL_TenderProductsSelection.UpdateProductListHeader();
                }
            });
        }
    },

    // Save the updated data of a product request
    SaveEditedProductRequest: function() {

        $("#editProductRequestDlgErrQuantity").addClass("hidden");

        var noErrors = true;
        var cacheId = $("#cacheId").val();
        var valProductId = $("#editProductRequestDlgProductId").val();
        var valProductName = $("#editProductRequestDlgProductName").text();
        var valBudget = $("#editProductRequestDlgBudget").val();
        var valNotes = $("#editProductRequestDlgNotes").val();
        var containerId = $("#editProductRequestDlgCategoryId").val();
        var tagId = $("#editProductRequestDlgSubCategoryId").val();
        var tagName = $("#editProductRequestDlgSubCategoryName").val();

        var valQuantity;
        if ($(".editProductRequestDlgQuantitySection").hasClass("hidden")) {
            valQuantity = 1;
        } else {
            valQuantity = $("#editProductRequestDlgQuantity").val();
        }

        if (valQuantity <= 0) {
            noErrors = false;
            $("#editProductRequestDlgErrQuantity").removeClass("hidden");
        }

        if (noErrors) {
            var productRequest = {
                CacheId: cacheId,
                ProductId: valProductId,
                ProductName: valProductName,
                Quantity: valQuantity,
                Budget: valBudget,
                Notes: valNotes,
                ContainerId: containerId,
                TagId: tagId,
                TagName: tagName
            }

            TECL_TenderProductsSelection.AddEditProductRequest(productRequest, false);
        }
    },

    // Add edit the save model
    AddEditProductRequest: function(saveModel, itemAddedDlgDisplayed) {

        // Call Server to add the product request to the cache and reload the product request list
        $.ajax({
            url: baseSiteUrl + "umbraco/Surface/Tender/AddEditProductRequest",
            type: "POST",
            data: {
                saveModel: saveModel
            },
            error: function(exc) {
                TECL_Master.ClearLock("AddEditProductRequest");
                $("#tenderProductRequestEditDlg").modal("hide");
            },
            success: function(productRequestHtml) {
                TECL_Master.ClearLock("AddEditProductRequest");

                if (productRequestHtml) {
                    $("#errProductRequests").addClass("hidden");

                    $("#ProductRequests").empty().html(productRequestHtml);
                }

                TECL_TenderProductsSelection.UpdateProductListHeader();

                $("#tenderProductRequestEditDlg").modal("hide");

                if (itemAddedDlgDisplayed) {
                    TECL_TenderProductsSelection.DisplayItemAddedDlg(false);
                }
            }
        });
    },

    // Update the product list section
    UpdateProductListHeader: function() {
        var productRequestItems = $("#ProductRequests").find(".TenderProductRequestItem");

        if (productRequestItems.length > 0) {
            $("#productListEmptyText").addClass("hidden");
        } else {
            $("#productListEmptyText").removeClass("hidden");
        }
    },

    // Display item added dlg for a set amount of time
    DisplayItemAddedDlg: function(show) {
        if (show) {
            $("#itemAddedDialog").modal("show");
        } else {
            function HideItemAddedDlg() {
                $("#itemAddedDialog").modal("hide");
            }

            // Wait a set amount of time till the dialog is closed
            setTimeout(HideItemAddedDlg, 1000);
        }
    }

};

var TECL_TenderQuoteDuration = {

    // Initialise the page
    Initialise: function() {

        TECL_DateTime.InitialiseDateTimePickers("#tenderPage");

        TECL_TenderGeneral.InitialiseAccordionControls();

        // Make the date section alternate depending on the checkboxes selection
        $("#sameDayTenderQuote").change(function() {
            if (this.checked) {
                $("#sameDateSection").removeClass("hidden");
                $("#quotePerProductDates").addClass("hidden");
            } else {
                $("#sameDateSection").addClass("hidden");
                $("#quotePerProductDates").removeClass("hidden");
            }
        });

        window.scrollTo(0, 0);

        // Update the progress bar
        TECL_TenderGeneral.UpdateProgressBar(1);
    },

    // Continue to the next stage
    ContinueToNextStage: function() {
        if (TECL_TenderQuoteDuration.ValidatePage()) {
            // Post back to change page view
            var cacheId = $("#cacheId").val();

            var quoteDurationData = TECL_TenderQuoteDuration.SerialisedData();

            if (TECL_Master.AddLock("ContinueNextStageSelectSuppliers")) {
                // Call Server to add the product request to the cache and reload the product request list
                $.ajax({
                    url: baseSiteUrl + "umbraco/Surface/Tender/ContinueNextStageSelectSuppliers",
                    type: "POST",
                    data: {
                        cacheId: cacheId,
                        saveData: quoteDurationData
                    },
                    error: function(exc) {
                        TECL_Master.ClearLock("ContinueNextStageSelectSuppliers");
                    },
                    success: function(partialViewHtml) {
                        TECL_Master.ClearLock("ContinueNextStageSelectSuppliers");
                        if (partialViewHtml) {
                            $("#tenderPage").html(partialViewHtml);
                        }
                    }
                });
            }
        }
    },

    // Vaidate the page
    ValidatePage: function() {

        $(".errLatestDate").each(function() {
            $(this).addClass("hidden");
        });
        $(".errEndDateBeforeToday").each(function() {
            $(this).addClass("hidden");
        });

        // Validate Controls
        var noErrors = true;
        if ($("#sameDayTenderQuote").is(":checked")) {

            var datesAreValid = TECL_TenderQuoteDuration.ValidDateRange($("#sameDateLatestDateVal"),
                $("#errQuoteDurationLatestDate"), $("#errQuoteDurationEndDateBeforeToday"));

            if (!datesAreValid) {
                // Invalid so show the error
                noErrors = false;
            }
        } else {

            $("#TenderProductDateSettingItems").find(".quoteDateDates").each(function() {

                var datesAreValid = TECL_TenderQuoteDuration.ValidDateRange($(this).find(".latestDate"),
                    $(this).find(".errLatestDate"), $(this).find(".errEndDateBeforeToday"));

                if (!datesAreValid) {
                    // Invalid so show the error and open the panel if it not already
                    noErrors = false;
                    $(this).parents(".panel-collapse").collapse("show");
                } else {
                    // Close any open panels that are valid and open so the invalid ones are highlighted
                    $(this).parents(".panel-collapse").collapse("hide");
                }
            });
        }
        return noErrors;
    },

    // Get the Serialised Data
    SerialisedData: function() {

        //get standard data
        var sameDayQuote = $("#sameDayTenderQuote").is(":checked");
        var sameDayLatestDate = $("#sameDateLatestDateVal").val();
        var sameDayNotes = $("#sameDateNoteVal").val();

        var durationSetting;
        if ($("#quoteDurationShortTerm").is(":checked")) {
            durationSetting = 1;
        } else if ($("#quoteDurationMidTerm").is(":checked")) {
            durationSetting = 2;
        } else if ($("#quoteDurationLongTerm").is(":checked")) {
            durationSetting = 3;
        } else {
            // Should never get here
            durationSetting = 3;
        }

        var productDateDatas = [];

        // Get products data
        $("#TenderProductDateSettingItems")
            .find(".panel-body")
            .each(function() {
                var latestDate = $(this).find(".latestDate").val();
                var dateNotes = $(this).find(".quoteDateNoteVal").val();

                productDatesData = {
                    LatestDate: latestDate,
                    DateNotes: dateNotes
                }

                productDateDatas.push(productDatesData);
            });

        var quoteDurationData = {
            AllItemQuotedOnSameDay: sameDayQuote,
            SameDayLatestDate: sameDayLatestDate,
            SameDayNotes: sameDayNotes,
            DurationSetting: durationSetting,
            ProductDateDatas: productDateDatas
        }
        return quoteDurationData;
    },

    // Validate date ranges
    ValidDateRange: function(finishDateCntrl, errorEndDateEmptyCntrl, errorEndDateBeforeTodayCntrl) {

        $(errorEndDateEmptyCntrl).addClass("hidden");
        $(errorEndDateBeforeTodayCntrl).addClass("hidden");

        var datesAreValid = true;
        if (finishDateCntrl) {
            var finishDate = finishDateCntrl.data("DateTimePicker").date();

            var errorEndDateEmpty = false;
            var errorEndDateBeforeToday = false;
            var dateNow = Date();

            if (finishDate) {
                if (moment(finishDate).isSameOrBefore(dateNow)) {
                    errorEndDateBeforeToday = true;
                }
            } else {
                errorEndDateEmpty = true;
            }

            if (errorEndDateEmpty) {
                $(errorEndDateEmptyCntrl).removeClass("hidden");
                datesAreValid = false;
            }

            if (errorEndDateBeforeToday) {
                $(errorEndDateBeforeTodayCntrl).removeClass("hidden");
                datesAreValid = false;
            }

        }
        return datesAreValid;
    }

};

var TECL_TenderSelectSuppliers = {

    // Initialise the page
    Initialise: function() {

        // Default the add supplier from list add icon to disabled
        $("#addSupplier").addClass("linkDisabled");

        // Change the highlighting 
        $("#ddlSuppliers").change(function() {
            var supplierId = $("#ddlSuppliers").val();
            var supplierName = $("#ddlSuppliers").data("kendoDropDownList").text();

            if (supplierId && supplierName) {
                $("#addSupplier").removeClass("linkDisabled");
            } else {
                $("#addSupplier").addClass("linkDisabled");
            }
        });

        // Update whether the selectSuppliersSection is visible
        $("input[name=SelectSuppliersRadioButtons]").change(function() {
            if ($("#edcoSelectSuppliers").is(":checked")) {
                $("#selectSuppliersSection").addClass("hidden");
            } else {
                $("#selectSuppliersSection").removeClass("hidden");
            }
        });

        window.scrollTo(0, 0);

        // Update the progress bar
        TECL_TenderGeneral.UpdateProgressBar(2);
    },

    // Update whether the selectSuppliersSection is visible
    UpdateSelectSuppliersSectionVisability: function() {
        if ($("#edcoSelectSuppliers").is(":checked")) {
            $("#selectSuppliersSection").addClass("hidden");
        } else {
            $("#selectSuppliersSection").removeClass("show");
        }
    },

    // Continue to the next stage
    ContinueToNextStage: function() {

        var edCoSelectSupplier;
        if ($("#edcoSelectSuppliers").is(":checked")) {
            edCoSelectSupplier = true;
        } else if ($("#userSelectSuppliers").is(":checked")) {
            edCoSelectSupplier = false;
        } else {
            // Should never get here
            edCoSelectSupplier = true;
        }

        if (TECL_TenderSelectSuppliers.ValidatePage(edCoSelectSupplier)) {
            var cacheId = $("#cacheId").val();

            if (TECL_Master.AddLock("ContinueNextStageUserInformation")) {
                // Call Server to add the product request to the cache and reload the product request list
                $.ajax({
                    url: baseSiteUrl + "umbraco/Surface/Tender/ContinueNextStageUserInformation",
                    type: "POST",
                    data: {
                        cacheId: cacheId,
                        edCoSelectSupplier: edCoSelectSupplier
                    },
                    error: function(exc) {
                        TECL_Master.ClearLock("ContinueNextStageUserInformation");
                    },
                    success: function(partialViewHtml) {
                        TECL_Master.ClearLock("ContinueNextStageUserInformation");
                        if (partialViewHtml) {
                            $("#tenderPage").html(partialViewHtml);
                        }
                    }
                });
            }
        }
    },

    // Vaidate the page
    ValidatePage: function(edCoSelectSupplier) {
        var noErrors = true;

        $("#errSelectedSuppliersMissing").addClass("hidden");

        if (!edCoSelectSupplier) {
            var listedSuppliers = $("#TenderSelectedSuppliers").find(".tenderSelectedSupplier");

            if (listedSuppliers.length <= 0) {
                noErrors = false;
                $("#errSelectedSuppliersMissing").removeClass("hidden");
            }
        }

        return noErrors;
    },

    // Added a recommended supplier
    AddRecommendedSupplier: function(supplierId, supplierName) {
        TECL_TenderSelectSuppliers.UpdateSelectedSupplierList(true, supplierId, supplierName);
    },

    // Added a listed supplier
    AddListedSupplier: function() {
        var supplierId = $("#ddlSuppliers").val();
        var supplierName = $("#ddlSuppliers").data("kendoDropDownList").text();

        if (supplierId && supplierName) {
            TECL_TenderSelectSuppliers.UpdateSelectedSupplierList(true, supplierId, supplierName);
        }
    },

    // Delete a selected supplier
    DeleteSelectedSupplier: function(supplierId) {
        TECL_TenderSelectSuppliers.UpdateSelectedSupplierList(false, supplierId, "name");
    },

    // Update the list of selected suppliers
    UpdateSelectedSupplierList: function(addSupplier, supplierId, supplierName) {
        var cacheId = $("#cacheId").val();

        if (TECL_Master.AddLock("UpdateSupplierList")) {
            // Call Server to add the product request to the cache and reload the product request list
            $.ajax({
                url: baseSiteUrl + "umbraco/Surface/Tender/UpdateSupplierList",
                type: "POST",
                data: {
                    cacheId: cacheId,
                    addSupplier: addSupplier,
                    supplierId: supplierId,
                    supplierName: supplierName
                },
                error: function(exc) {
                    TECL_Master.ClearLock("UpdateSupplierList");
                },
                success: function(partialViewHtml) {
                    TECL_Master.ClearLock("UpdateSupplierList");
                    if (partialViewHtml) {
                        $("#errSelectedSuppliersMissing").addClass("hidden");

                        $("#TenderSelectedSuppliers").html(partialViewHtml);

                        // Add and remove recommended suppliers if they have been selected
                        $("#TenderRecommendedSuppliers").find(".recommendedSupplierEntry").each(function() {
                            var recommendedSupplierId = $(this).find("[name='tenderSupplierId']").val();
                            if (supplierId === recommendedSupplierId) {
                                if (addSupplier) {
                                    $(this).addClass("hidden");
                                } else {
                                    $(this).removeClass("hidden");
                                }
                            }
                        });
                    }
                }
            });
        }
    },

    // Added a listed supplier
    ShowCalculationWorkingsDialog: function() {
        $("#tenderRecommendedSuppliersCalcInfoEditDlg").modal("show");
    }

};

var TECL_TenderUserInformation = {

    // Initialise the page
    Initialise: function() {

        // Create the file upload control
        $("#files").kendoUpload({
            async: {
                saveUrl: baseSiteUrl + "umbraco/Surface/Tender/SaveUploadFile?cacheId=" + $("#cacheId").val(),
                removeUrl: baseSiteUrl + "umbraco/Surface/Tender/RemoveUploadFile?cacheId=" + $("#cacheId").val(),
                autoUpload: true
            }
        });

        window.scrollTo(0, 0);

        // Update the progress bar
        TECL_TenderGeneral.UpdateProgressBar(3);
    },

    // Search for a organisation
    OrganisationSearch: function() {
        TECL_TenderUserInformation.ClearValidationErrors();

        var searchText = $("#userOrganisationNameVal").val();
        if (!searchText) {
            $("#errUserDetailsOrganisationSearch").removeClass("hidden");
        } else {
            if (TECL_Master.AddLock("OrganisationSearch")) {

                $.ajax({
                    url: baseSiteUrl + "umbraco/Surface/Tender/OrganisationSearch",
                    type: "POST",
                    data: {
                        searchText: searchText
                    },
                    error: function(exc) {
                        TECL_Master.ClearLock("OrganisationSearch");
                    },
                    success: function(partialViewHtml) {
                        TECL_Master.ClearLock("OrganisationSearch");
                        if (partialViewHtml) {
                            $("#OrganisationSearchResults").removeClass("hidden");
                            $("#OrganisationSearchResults").html(partialViewHtml);

                        }
                    }
                });
            }
        }
    },

    // Validate the login info and try and authenticate the details
    LogInUser: function() {
        TECL_TenderUserInformation.ClearValidationErrors();

        var emailAddress = $("#loginEmailAddressVal").val();
        var password = $("#loginPasswordVal").val();

        var noErrors = true;

        if (!emailAddress || !TECL_Master.IsEmailAddress(emailAddress)) {
            $("#errLoginEmailAddress").removeClass("hidden");
            noErrors = false;
        }

        if (!password) {
            $("#errLoginPassword").removeClass("hidden");
            noErrors = false;
        }

        if (noErrors) {
            if (TECL_Master.AddLock("AuthenticateUserDetails")) {
                var cacheId = $("#cacheId").val();

                $.ajax({
                    url: baseSiteUrl + "umbraco/Surface/Tender/AuthenticateUserDetails",
                    type: "POST",
                    dataType: "json",
                    data: {
                        cacheId: cacheId,
                        emailAddress: emailAddress,
                        password: password
                    },
                    error: function(exc) {
                        TECL_Master.ClearLock("AuthenticateUserDetails");
                    },
                    success: function(viewModel) {
                        TECL_Master.ClearLock("AuthenticateUserDetails");

                        if (viewModel && viewModel.ErrorCode === 0) {
                            // Reload the view so the correct controls are displayed
                            TECL_TenderUserInformation.ReloadUserInformationView();
                        } else {
                            $("#errLoginFail").removeClass("hidden");
                        }
                    }
                });
            }
        }
    },

    // Clear all validation error text
    ClearValidationErrors: function() {
        $("#errUserDetailsTitle").addClass("hidden");
        $("#errUserDetailsFirstName").addClass("hidden");
        $("#errUserDetailsLastName").addClass("hidden");
        $("#errUserDetailsOrganisation").addClass("hidden");
        $("#errUserDetailsOrganisationSearch").addClass("hidden");
        $("#errUserDetailsPosition").addClass("hidden");
        $("#errUserDetailsEmailAddress").addClass("hidden");
        $("#errUserDetailsConfirmEmailAddress").addClass("hidden");
        $("#errUserDetailsPassword").addClass("hidden");
        $("#errUserDetailsConfirmPassword").addClass("hidden");
        $("#errLoginEmailAddress").addClass("hidden");
        $("#errLoginPassword").addClass("hidden");
        $("#errLoginFail").addClass("hidden");
    },

    // Validate the user info section
    ValidateUserInfo: function() {

        TECL_TenderUserInformation.ClearValidationErrors();

        var title = $("#ddlTitles").val();
        var firstName = $("#userFirstNameVal").val();
        var lastName = $("#userLastNameVal").val();
        var organisationId = $("#userOrganisationIdVal").val();
        var ddlOrganisationId = $("#ddlOrganisations").val();
        var positionId = $("#ddlPositions").val();
        var emailAddress = $("#userEmailAddressVal").val();
        var confirmEmailAddress = $("#userConfirmEmailAddressVal").val();
        var password = $("#userPasswordVal").val();
        var confirmPassword = $("#userConfirmPasswordVal").val();

        var noErrors = true;

        if (!title) {
            $("#errUserDetailsTitle").removeClass("hidden");
            noErrors = false;
        }

        if (!firstName) {
            $("#errUserDetailsFirstName").removeClass("hidden");
            noErrors = false;
        }

        if (!lastName) {
            $("#errUserDetailsLastName").removeClass("hidden");
            noErrors = false;
        }

        if (!organisationId && !ddlOrganisationId) {
            $("#errUserDetailsOrganisation").removeClass("hidden");
            noErrors = false;
        }

        if (!positionId) {
            $("#errUserDetailsPosition").removeClass("hidden");
            noErrors = false;
        }

        if (!emailAddress || !TECL_Master.IsEmailAddress(emailAddress)) {
            $("#errUserDetailsEmailAddress").removeClass("hidden");
            noErrors = false;

        } else if (emailAddress !== confirmEmailAddress) {
            $("#errUserDetailsConfirmEmailAddress").removeClass("hidden");
            noErrors = false;
        }

        var passwordSection = $("#passwordSection");
        if (passwordSection.length > 0) {
            if (!password || password.length < 5) {
                $("#errUserDetailsPassword").removeClass("hidden");
                noErrors = false;

            } else if (password !== confirmPassword) {
                $("#errUserDetailsConfirmPassword").removeClass("hidden");
                noErrors = false;
            }
        }
        return noErrors;
    },

    // Continue to the next stage
    SaveTender: function() {

        if (TECL_TenderUserInformation.ValidateUserInfo()) {
            var cacheId = $("#cacheId").val();

            if (TECL_Master.AddLock("SaveTender")) {

                var userInfoData = TECL_TenderUserInformation.SerialisedData();

                // Call Server to save the tender
                $.ajax({
                    url: baseSiteUrl + "umbraco/Surface/Tender/SaveTender",
                    type: "POST",
                    dataType: "json",
                    data: {
                        cacheId: cacheId,
                        userInfoData: userInfoData
                    },
                    error: function(exc) {
                        TECL_Master.ClearLock("SaveTender");
                    },
                    success: function(viewModel) {
                        TECL_Master.ClearLock("SaveTender");

                        if (viewModel.ErrorCode === 0) {
                            TECL_TenderUserInformation.ContinueToNextStage();
                        } else {
                            TECL_TenderGeneral.ShowErrorDialog(viewModel.ErrorMessage);
                        }
                    }
                });
            }
        }
    },

    // Continue to the next stage
    ContinueToNextStage: function() {
        if (TECL_Master.AddLock("ContinueToNextStage")) {
            var cacheId = $("#cacheId").val();

            // Call Server to save the tender
            $.ajax({
                url: baseSiteUrl + "umbraco/Surface/Tender/ContinueNextStageCompletionPage",
                type: "POST",
                data: {
                    cacheId: cacheId
                },
                error: function(exc) {
                    TECL_Master.ClearLock("ContinueToNextStage");
                },
                success: function(partialViewHtml) {
                    TECL_Master.ClearLock("ContinueToNextStage");

                    if (partialViewHtml) {
                        $("#tenderPage").html(partialViewHtml);
                    }
                }
            });
        }
    },

    // Get the Serialised Data
    SerialisedData: function() {
        //get standard data
        var webAccountId = $("#userWebAccountIdVal").val();
        var title = $("#ddlTitles").val();
        var firstName = $("#userFirstNameVal").val();
        var lastName = $("#userLastNameVal").val();
        var positionId = $("#ddlPositions").val();
        var emailAddress = $("#userEmailAddressVal").val();
        var password = $("#userPasswordVal").val();

        var organisationId;
        var organisationName;
        if ($("#ddlOrganisations").length > 0) {
            organisationId = $("#ddlOrganisations").val();
            organisationName = $("#ddlOrganisations").data("kendoDropDownList").text();
        } else {
            organisationId = $("#userOrganisationIdVal").val();
            organisationName = $("#userOrganisationNameVal").val();
        }

        var userInfoData = {
            WebAccountId: webAccountId,
            Title: title,
            FirstName: firstName,
            LastName: lastName,
            OrganisationId: organisationId,
            OrganisationName: organisationName,
            PositionId: positionId,
            EmailAddress: emailAddress,
            Password: password
        }
        return userInfoData;
    },

    // Forgotten password dialog
    ShowForgottenPasswordDlg: function() {
        $("#tenderForgottenPasswordDlg").modal("show");
    },

    // Reload the user information view
    ReloadUserInformationView: function() {

        if (TECL_Master.AddLock("ReloadUserInformationView")) {
            var cacheId = $("#cacheId").val();

            // Call Server to add the product request to the cache and reload the product request list
            $.ajax({
                url: baseSiteUrl + "umbraco/Surface/Tender/ReloadUserInformationView",
                type: "POST",
                data: {
                    cacheId: cacheId
                },
                error: function(exc) {
                    TECL_Master.ClearLock("ReloadUserInformationView");
                },
                success: function(partialViewHtml) {
                    TECL_Master.ClearLock("ReloadUserInformationView");
                    if (partialViewHtml) {
                        $("#tenderPage").html(partialViewHtml);
                    }
                }
            });
        }
    }

};

var TECL_TenderCompletion = {

    // Initialise the page
    Initialise: function() {

        window.scrollTo(0, 0);

        // Update the progress bar
        TECL_TenderGeneral.UpdateProgressBar(4);
    },

    // Print the tender
    ShowTenderReport: function() {
        var cacheId = $("#cacheId").val();
        window.open(  baseSiteUrl + "umbraco/Surface/Tender/DownloadReportFile?cacheId=" + cacheId, '_blank' );
    },

    // Goto home page
    RedirectToHome: function() {
        window.location = baseSiteUrl;
    },

    // Create a new tender
    CreateNewTender: function() {
        if (TECL_Master.AddLock("ResetToFirstPage")) {
            // Call Server to add the product request to the cache and reload the product request list
            $.ajax({
                url: baseSiteUrl + "umbraco/Surface/Tender/ResetToFirstPage",
                type: "POST",
                error: function(exc) {
                    TECL_Master.ClearLock("ResetToFirstPage");
                },
                success: function(partialViewHtml) {
                    TECL_Master.ClearLock("ResetToFirstPage");
                    if (partialViewHtml) {
                        $("#tenderPage").html(partialViewHtml);
                    }
                }
            });
        }
    }

};