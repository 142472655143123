var TECL_MyAccount = {

    Unsubscribe: function (personId, attributeId) {
        var token = $("[name='__RequestVerificationToken']").val();
        $.ajax({
            url: url,
            data: {
                __RequestVerificationToken: token,
                personId: personId,
                attributeId: attributeId
            },
            dataType: 'json',
            cache: true,
            type: "POST",
            success: function (data) {
                if (data.length !== 0) {

                } else {

                }
            },
            error: function (xhr, status, error) {
                console.log(xhr.responseText);
                $("#AddressResult_Error").show(500);
                alert("message : \n" +
                    "An error occurred, for more info check the js console" +
                    "\n status : \n" + status + " \n error : \n" + error);
            }
        });
    }
}
