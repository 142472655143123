var $animationElements = $(".animated");
var $window = $(window);

function check_if_in_view() {
    var windowHeight = $window.height();
    var windowTopPosition = $window.scrollTop();
    var windowBottomPosition = (windowTopPosition + windowHeight);

    $.each($animationElements,
        function () {
            var $element = $(this);
            var elementHeight = $element.outerHeight();
            var elementTopPosition = $element.offset().top;
            var elementBottomPosition = (elementTopPosition + elementHeight);

            //check to see if this current container is within viewport
            if ((elementBottomPosition >= windowTopPosition) &&
            (elementTopPosition <= windowBottomPosition)) {
                var $animationType = $element.data("edco-animation-type");
                $element.addClass($animationType);
            }
        });
}

$(window).scroll(check_if_in_view);
$(window).resize(check_if_in_view);