//Basket methods
var TECL_Basket = {

    AddToBasket: function (e, productId, stock) {
        //Get quantity to add
        var qty = $("#qty_" + productId).val();

        if (qty > stock) {
            //message to explain there isn't enough stock.
            var itemOrItems = "items";
            if (stock === 1) {
                itemOrItems = "item";
            }
            alert("There are only " + stock + " " + itemOrItems + " available. Please reduce your quantity and try again.");
        } else {
            //add to basket
            $.ajax({
                url: "/umbraco/Surface/BasketSurface/AddToBasket", //url path to the controller action
                type: "POST",
                data: {
                    id: productId,
                    quantity: qty
                },
                success: function (data) {
                    //do whatever you need to do with 'data'
                    if (data.Error !== undefined) {
                        alert(data.Error);
                    } else {
                        var addedAlert = $("#added_" + productId);
                        var clickedButton = $("#btnAddToCart_" + productId);
                        var microBasketId = data.OrderHeaderId;
                        TECL_Basket.UpdateMicroBasket(e, microBasketId);
                        addedAlert.show(500).delay(1000).hide(500);
                    }
                },
                error: function (xhr, status, error) {
                    console.log(xhr.responseText);
                    $("#AddressResult_Error").show(500);
                    alert("message : \n" +
                        "An error occurred, for more info check the js console" +
                        "\n status : \n" +
                        status +
                        " \n error : \n" +
                        error);
                }
            });
        }

        e.preventDefault();
    }
    ,
    BuyAgain: function (e, orderHeaderId) {
        $.ajax({
            url: "/umbraco/Surface/BasketSurface/BuyAgain", //url path to the controller action
            type: "POST",
            data: {
                id: orderHeaderId
            }
        })
        .success(function (data) {
            //do whatever you need to do with 'data'
            if (data.Error !== undefined) {
                alert(data.Error);
            } else {
                var buyAgainAlert = $("#buyAgain_" + orderHeaderId);
                var microBasketId = data.OrderHeaderId;
                TECL_Basket.UpdateMicroBasket(e, microBasketId);
                buyAgainAlert.show(500).delay(1000).hide(500);
            }
        });
        e.preventDefault();
    }
    ,
    UpdateMicroBasket: function (e, microBasketId) {
        $.ajax({
            url: "/umbraco/Surface/BasketSurface/GetMicroBasketById",
            type: "POST",
            data: {
                microBasketId: microBasketId
            }
        })
        .success(function (data) {
            //update the microbasket
            if (data.Error !== undefined) {
                alert(data.Error);
            }

            //replace the basket with the returned data
            if (data.BasketData !== undefined) {
                $(".microBasket").replaceWith(data.BasketData);
            }
        });
        e.preventDefault();
    }
    ,
    RemoveFromBasket: function (e, orderHeaderId, orderLineId) {
        $.ajax({
            url: "/umbraco/Surface/BasketSurface/RemoveFromBasket", //url path to the controller action
            type: "POST",
            data: {
                orderHeaderId: orderHeaderId,
                orderLineId: orderLineId
            }
        })
        .success(function (data) {
            //do whatever you need to do with 'data'
            if (data.Error !== undefined) {
                alert(data.Error);
            } else {
                var microBasketId = $("#microBasketId").val();
                if (data.ItemRemoved === true) {
                    //Update microbasket
                    TECL_Basket.UpdateMicroBasket(e, microBasketId);
                    //remove order line
                    $("#" + orderLineId).remove();
                    //Refresh basket totals
                    $("#basketSubtotal")
                        .replaceWith("<span id=\"basketSubtotal\">Subtotal (" +
                            data.NewQuantity +
                            " item(s)): " + data.BasketCurrency +
                            data.NewOrderTotal.toFixed(2) +
                            "</span>");
                    $("#basketTotalExVat").replaceWith("<div class=\"basketTotalExVat\" id=\"basketTotalExVat\">Total (ex VAT): " + data.BasketCurrency + data.NewTotalExVat.toFixed(2) + "</div>");
                    $("#basketVat").replaceWith("<div class=\"basketVat\" id=\"basketVat\">VAT: " + data.BasketCurrency + data.NewVat.toFixed(2) + "</div>");
                    $("#basketTotal").replaceWith("<div class=\"basketTotal\" id=\"basketTotal\">Total (inc VAT): " + data.BasketCurrency + data.NewTotalIncVat.toFixed(2) + "</div>");
                    window.location.reload();
                }
            }
        });
        e.preventDefault();
    }
    ,
    DeleteDeliveryAddress: function (deliveryAddressId) {
        $("#confirmDeleteAddressButton").unbind().click(function () {
            TECL_Basket.ConfirmDeleteDeliveryAddress(deliveryAddressId);
        });
        $("#deleteAddressModal").modal('toggle');
    }
    ,
    ConfirmDeleteDeliveryAddress: function (deliveryAddressId) {
        var token = $("[name='__RequestVerificationToken']").val();
        $.ajax({
            url: "/umbraco/Surface/BasketSurface/DeleteDeliveryAddress", //url path to the controller action
            type: "POST",
            data: {
                __RequestVerificationToken: token,
                id: deliveryAddressId
            }
        })
        .success(function (data) {
            //do whatever you need to do with 'data'
            if (data.Error !== undefined) {
                alert(data.Error);
            } else {
                //reload the page
                window.location.href = window.location.href;
            }
        });
    }
    ,
    UpdateItemQuantity: function (e, orderHeaderId, orderLineId, productId) {
        //Get updated quantity from page
        var newQty = $("#quantity_" + productId).val();
        var originalQty = $("#initialQuantity_" + productId).val();

        //only call for update if qty has changed and qty greater than 0
        if (newQty !== originalQty && newQty > 0) {
            $.ajax({
                url: "/umbraco/Surface/BasketSurface/UpdateBasketQuantity", //url path to the controller action
                type: "POST",
                data: {
                    orderHeaderId: orderHeaderId,
                    orderLineId: orderLineId,
                    productId: productId,
                    newQuantity: newQty
                }
            })
            .success(function (data) {
                //do whatever you need to do with 'data'
                if (data.Error !== undefined) {
                    alert(data.Error);
                } else {
                    var microBasketId = $("#microBasketId").val();
                    if (data.QuantityUpdated === true) {
                        //Update microbasket
                        TECL_Basket.UpdateMicroBasket(e, microBasketId);
                        //Refresh basket totals
                        $("#basketSubtotal")
                            .replaceWith("<span id=\"basketSubtotal\">Subtotal (" +
                                data.NewQuantity +
                                " item(s)): " + data.BasketCurrency +
                                data.NewOrderTotal.toFixed(2) +
                                "</span>");
                        $("#basketTotalExVat").replaceWith("<div class=\"basketTotalExVat\" id=\"basketTotalExVat\">Total (ex VAT): " + data.BasketCurrency + data.NewTotalExVat.toFixed(2) + "</div>");
                        $("#basketVat").replaceWith("<div class=\"basketVat\" id=\"basketVat\">VAT: " + data.BasketCurrency + data.NewVat.toFixed(2) + "</div>");
                        $("#basketTotal").replaceWith("<div class=\"basketTotal\" id=\"basketTotal\">Total (inc VAT): " + data.BasketCurrency + data.NewTotalIncVat.toFixed(2) + "</div>");
                        //Refresh Item total
                        $("#itemPrice_" + productId).text(data.BasketCurrency + data.NewItemPriceExcVat.toFixed(2));
                        //set new original qty
                        $("#initialQuantity_" + productId).val(data.NewQuantity);
                        //Show the updated tickbox
                        var updatedAlert = $("#updated_" + productId);
                        updatedAlert.show(500).delay(1000).hide(500);
                        window.location.reload();
                    }
                }
            });
        }

        //Send message for -ve quantities.
        if (newQty <= 0) {
            alert("Please enter a new quantity that is greater than zero.");
        }

        e.preventDefault();
    }
    ,
    ApplyVoucherCode: function (e, orderHeaderId, currencySymbol) {
        //Get Voucher code from page
        var voucherCode = $("#voucherCodeValue").val();

        //only call for non blank voucher code
        if (voucherCode !== "") {
            //disable the button to prevent multiple clicks
            $("#applyVoucherButton").prop("disabled", true);
            $.ajax({
                url: "/umbraco/Surface/BasketSurface/ApplyVoucherCode", //url path to the controller action
                type: "POST",
                data: {
                    orderHeaderId: orderHeaderId,
                    voucherCode: voucherCode
                }
            })
            .success(function (data) {
                //do whatever you need to do with 'data'
                if (data.Error !== undefined) {
                    alert(data.Error);
                } else if (data.Warning !== undefined) {
                    //show warning
                    $("#voucherAlert").text(data.Warning).show();
                } else {
                    var microBasketId = $("#microBasketId").val();
                    if (data.VoucherApplied === true) {
                        //Update microbasket
                        TECL_Basket.UpdateMicroBasket(e, microBasketId);

                        //Show discounted totals?
                        $("#discountIncluded").text("Voucher discount included: " + currencySymbol + data.VoucherDiscountValue);

                        //Show the updated tickbox
                        var appliedAlert = $("#appliedVoucherCode");
                        appliedAlert.show(500).delay(1000).hide(500);

                        //Hide any previous alerts
                        $("#voucherAlert").hide();
                    }
                }
                //re-enable button
                $("#applyVoucherButton").prop("disabled", false);
            });
        }

        e.preventDefault();
    }
    ,
    SelectAddress: function (e, addressId) {
        //Uncheck all address radio button inputs
        $(".radioAddressInput").prop("checked", false);
        //check the selected address input element
        $("#" + addressId).prop("checked", true);
        $(".selectDeliveryAddress").removeClass("activeAddress");
        var parent = $("#" + addressId).parent().parent(".selectDeliveryAddress");
        parent.addClass("activeAddress");
    }
    ,
    SelectPaymentMethod: function (e, paymentMethodId) {
        //Check to see if this is a disabled element
        var disabled = $("#div_" + paymentMethodId).attr("disabled");

        //Only do stuff for enabled radiobuttons
        if (disabled !== "disabled") {
            //Uncheck all address radio button inputs
            $(".radioPaymentMethodInput").prop("checked", false);
            //check the selected address input element
            $("#" + paymentMethodId).prop("checked", true);
        } else {
            //uncheck the selected address input element
            $("#" + paymentMethodId).prop("checked", false);
            //e.preventDefault();
        }
    }
    ,
    ToggleCompleteOrderButton: function () {
        if ($("#completeOrderButton").attr("disabled")) {
            $("#completeOrderButton").removeAttr("disabled");
            $("#completeOrderButton").removeAttr("aria-disabled");
        } else {
            $("#completeOrderButton").attr("disabled", "disabled");
            $("#completeOrderButton").attr("aria-disabled", true);
        }
    }
    ,
    DeleteDelegate: function (e, delegateId, orderLineId) {
        var token = $("[name='__RequestVerificationToken']").val();

        //delete delegate
        $.ajax({
            url: "/umbraco/Surface/BasketSurface/DeleteDelegate", //url path to the controller action
            type: "POST",
            data: {
                __RequestVerificationToken: token,
                id: delegateId,
                orderLineId: orderLineId
            },
            success: function (data) {
                //do whatever you need to do with 'data'
                if (data.Error !== undefined) {
                    alert(data.Error);
                } else {
                    //refresh page
                    location.reload();
                }
            },
            error: function (xhr, status, error) {
                console.log(xhr.responseText);
                $("#AddressResult_Error").show(500);
                alert("message : \n" +
                    "An error occurred, for more info check the js console" +
                    "\n status : \n" +
                    status +
                    " \n error : \n" +
                    error);
            }
        });
    }
    ,
    RegisterAddressTypeChange: function (myRadio) {
        registerAddressTypeValue = myRadio.value;
        $("#registerAddressValue").val(myRadio.value);

        //clear fields and hide sections
        $("#shippingAddressFields").hide();
        $("#billingAddressFields").hide();
        $("#schoolCombinedAddress").hide();
        $("#userFields").hide();
        $("input[name='ShowCombinedSchoolAddress']").val("");
        $("#schoolNameInputField").val("");
        $("#SearchPostcode").val("");
        $("#ShipToSearchPostcode").val("");
        $("input[name='OrganisationSchoolName']").val("");
        $("input[name='AddressLine1']").val("");
        $("input[name='AddressLine2']").val("");
        $("input[name='AddressLine3']").val("");
        $("input[name='Postcode']").val("");
        $("#Country").val("");
        $("#ShipToDifferentAddress").removeProp("checked");
        $("#ShipToDifferentAddressCol").hide();
        $("#ShipToDifferentAddress").prop('checked', false);
        $("#shipToAddress").hide();
        $("input[name='ShipToOrganisationSchoolName']").val("");
        $("input[name='ShipToAddressLine1']").val("");
        $("input[name='ShipToAddressLine2']").val("");
        $("input[name='ShipToAddressLine3']").val("");
        $("input[name='ShipToPostcode']").val("");
        $("#ShipToCountry").val("");
        $("#addressDropDown").empty();
        $("#addressDropDown").hide();
        $("#shipToAddressDropDown").empty();
        $("#shipToAddressDropDown").hide();
        $("#addressDropDownLabel").hide();
        $("#shipToAddressDropDownLabel").hide();

        var coursesOnly = $("#CoursesOnly").val();

        if (myRadio.value === "HOME") {
            //Disable school name
            $("#schoolNameInputField").prop("disabled", true);
            $("#schoolNameInputDiv").hide(500);
            $("#invoiceSchool").hide();
            $("input[name=SelectedPaymentMethod][value='CRD']").prop("checked", true);

            if (coursesOnly === "False") {
                $("#ShipToDifferentAddressCol").show();
            }
            $("#SearchPostcodeLabel").text("Enter your postcode");

            //enable guest checkout address fields
            $("input[name='OrganisationSchoolName']").prop("readonly", false);
            $("input[name='AddressLine1']").prop("readonly", false);
            $("input[name='AddressLine2']").prop("readonly", false);
            $("input[name='AddressLine3']").prop("readonly", false);
            $("input[name='Postcode']").prop("readonly", false);
            $("#Country").prop("disabled", false);
        } else {
            //enable school name
            //$("#schoolNameInputField").prop("disabled", false);
            //$("#schoolNameInputDiv").show(500);
            $("#invoiceSchool").show();
            if (coursesOnly === "False") {
                $("#ShipToDifferentAddressCol").show();
            }
            //$("#ShipToDifferentAddressCol").hide();
            $("#SearchPostcodeLabel").text("Enter your school postcode");

            //disable guest checkout address fields
            $("input[name='OrganisationSchoolName']").prop("readonly", true);
            $("input[name='AddressLine1']").prop("readonly", true);
            $("input[name='AddressLine2']").prop("readonly", true);
            $("input[name='AddressLine3']").prop("readonly", true);
            $("input[name='Postcode']").prop("readonly", true);
            $("#Country").prop("disabled", true);
        }
    }
    ,
    AddressSearch: function (isShippingAddress) {
        $("#AddressResult_Error").hide();
        $("#ShipToAddressResult_Error").hide();
        var url = "/umbraco/Surface/WebAccountSurface/AddressSearch";
        var addressCollectionMethod = "UKSCHOOL";
        var registerAddressValue = $("#registerAddressValue").val();
        var schoolName = $("input[name='SchoolName']").val();
        var postcode = $("input[name='SearchPostcode']").val();
        if (registerAddressValue === "HOME") {
            addressCollectionMethod = "UKPAF";
        }
        if (isShippingAddress) {
            schoolName = "";
            postcode = $("input[name='ShipToSearchPostcode']").val();
            addressCollectionMethod = "UKPAF";
            registerAddressValue = "HOME";
        }

        $.ajax({
            url: url,
            data: {
                schoolName: schoolName,
                postcode: postcode,
                town: "",
                rollNumber: "",
                country: "",
                registerAddressType: registerAddressValue,
                addressCollectionMethod: addressCollectionMethod
            },
            dataType: 'json',
            cache: true,
            type: "POST",
            success: function (data) {
                var dropDown = $("#addressDropDown");
                var dropDownLabel = $("#addressDropDownLabel");

                if (isShippingAddress) {
                    dropDown = $("#shipToAddressDropDown");
                    dropDownLabel = $("#shipToAddressDropDownLabel");
                }

                if (data.Addresses.length !== 0) {
                    //Populate dropdown
                    dropDown.empty();
                    //Add empty " - select - " value
                    $(document.createElement('option'))
                                    .attr('value', "")
                                    .text("Select your address")
                                    .appendTo(dropDown);
                    if (data.Type === "SCHOOL") {
                        $.each(data.Addresses,
                            function (index) {
                                $(document.createElement('option'))
                                    .attr('value', data.Addresses[index].Id)
                                    .text(data.Addresses[index].OrgName +
                                        ', ' +
                                        data.Addresses[index].Address1 +
                                        ', ' +
                                        data.Addresses[index].Town +
                                        ', ' +
                                        data.Addresses[index].Postcode)
                                    .appendTo(dropDown);
                            });
                    } else {
                        $.each(data.Addresses,
                            function (index) {
                                $(document.createElement('option'))
                                    .attr('value', data.Addresses[index].PostKey)
                                    .text(data.Addresses[index].AddressText)
                                    .appendTo(dropDown);
                            });
                    }
                    //unhide dropdown
                    dropDownLabel.fadeIn(1000);
                    dropDown.fadeIn(1000);
                    dropDown.focus();
                    //Hide no results message
                    $("#AddressResult_NoResults").hide();
                    $("#ShipToAddressResult_NoResults").hide();
                    //populate address fields from first dropdown item
                    //TECL_Basket.PopulateAddressFieldsFromDropdown(dropDown[0], isShippingAddress);
                } else {
                    //Clear and hide dropdown
                    dropDown.empty();
                    dropDownLabel.hide(500);
                    dropDown.hide(500);
                    //Show no results message
                    if (isShippingAddress) {
                        $("#ShipToAddressResult_NoResults").show(500);
                        $("#shippingAddressFields").show();
                    } else {
                        //unhide address fields
                        if (addressCollectionMethod === "UKSCHOOL") {
                            //popup modal window.
                            $("#noSchoolResultsModal").modal("show");
                        } else {
                            $("#AddressResult_NoResults").show(500);
                            //show address for manual entry
                            $("#billingAddressFields").show();
                            $("#userFields").show();
                        }
                    }

                }
            },
            error: function (xhr, status, error) {
                console.log(xhr.responseText);
                if (isShippingAddress) {
                    $("ShipToAddressResult_Error").show(500);
                } else {
                    $("#AddressResult_Error").show(500);
                }
                alert("message : \n" +
                    "An error occurred, for more info check the js console" +
                    "\n status : \n" + status + " \n error : \n" + error);
            }
        });
    }
    ,
    ToggleShipToAddress: function () {
        var registerAddressValue = $("#registerAddressValue").val();
        var shipToDifferentAddress = $("#ShipToDifferentAddress").prop('checked');
        if (shipToDifferentAddress) {
            $("#shipToAddress").show();
            //Don't allow invoice payments for schools if shipping to another address
            
            if (registerAddressValue === "SCHOOL") {
                $("#invoiceSchool").hide();
                $("input[name=SelectedPaymentMethod][value='CRD']").prop("checked", true);
                $("#schoolInvoiceMessage").show();
            }
        } else {
            $("#shipToAddress").hide();
            if (registerAddressValue === "SCHOOL") {
                $("#invoiceSchool").show();
                $("#schoolInvoiceMessage").hide();
            }
        }
    }
    ,
    PopulateAddressFieldsFromDropdown: function (myDropdown, isShippingAddress) {
        var dropdownSelectedValue = myDropdown.value;
        var addressType = $("#registerAddressValue").val();
        var url = "/umbraco/Surface/WebAccountSurface/GetAddressByAddressId";

        //If school ship to, force type to be home address
        if (isShippingAddress && addressType === "SCHOOL") {
            addressType = "HOME";
        }

        //Go off to get the full address details back from the server
        $.ajax({
            url: url,
            data: {
                addressId: dropdownSelectedValue,
                addressType: addressType
            },
            dataType: 'json',
            cache: true,
            type: "POST",
            success: function (data) {
                //Populate address fields
                if (isShippingAddress) {
                    if (data.AddressType === "SCHOOL") {
                        $.each(data.OrgList,
                            function (index) {
                                //populate address fields.
                                if (data.OrgList[index].Id === dropdownSelectedValue) {
                                    $("input[name='ShipToOrganisationSchoolName']").val(data.OrgList[index].OrgName);
                                    $("input[name='ShipToAddressLine1']").val(data.OrgList[index].Address1);
                                    $("input[name='ShipToAddressLine2']").val(data.OrgList[index].Town);
                                    $("input[name='ShipToAddressLine3']").val(data.OrgList[index].Region);
                                    $("input[name='ShipToPostcode']").val(data.OrgList[index].Postcode);
                                    $("#ShipToCountry").val(data.OrgList[index].CountryId.toString());
                                    $("#shippingAddressFields").show();
                                    $("input[name='ShowShippingAddressFields']").val(true);
                                }
                            });
                    } else {
                        $("input[name='ShipToOrganisationSchoolName']").val("");
                        $("input[name='ShipToAddressLine1']").val(data.Address.OrgName);
                        $("input[name='ShipToAddressLine2']").val(data.Address.DefaultAddress.Town);
                        $("input[name='ShipToAddressLine3']").val(data.Address.DefaultAddress.Region);
                        $("input[name='ShipToPostcode']").val(data.Address.DefaultAddress.Postcode);
                        $("#ShipToCountry").val(data.Address.DefaultAddress.CountryId.toString());
                        $("#shippingAddressFields").show();
                        $("input[name='ShowShippingAddressFields']").val(true);
                    }
                } else {
                    if (data.AddressType === "SCHOOL") {
                        $.each(data.OrgList,
                            function (index) {
                                //populate address fields.
                                if (data.OrgList[index].Id === dropdownSelectedValue) {
                                    $("input[name='OrganisationSchoolName']").val(data.OrgList[index].OrgName);
                                    $("input[name='AddressLine1']").val(data.OrgList[index].Address1);
                                    $("input[name='AddressLine2']").val(data.OrgList[index].Town);
                                    $("input[name='AddressLine3']").val(data.OrgList[index].Region);
                                    $("input[name='Postcode']").val(data.OrgList[index].Postcode);
                                    $("#Country").val(data.OrgList[index].CountryId.toString());
                                    $("#hiddenCountry").val(data.OrgList[index].CountryId.toString());
                                    //populate combined school address field and unhide it
                                    var schoolAddressDisplayOnly = "";
                                    if (data.OrgList[index].OrgName !== undefined && data.OrgList[index].OrgName !== "") {
                                        schoolAddressDisplayOnly += data.OrgList[index].OrgName + "\r\n";
                                    }
                                    if (data.OrgList[index].Address1 !== undefined && data.OrgList[index].Address1 !== "") {
                                        schoolAddressDisplayOnly += data.OrgList[index].Address1 + "\r\n";
                                    }
                                    if (data.OrgList[index].Town !== undefined && data.OrgList[index].Town !== "") {
                                        schoolAddressDisplayOnly += data.OrgList[index].Town + "\r\n";
                                    }
                                    if (data.OrgList[index].Region !== undefined && data.OrgList[index].Region !== "") {
                                        schoolAddressDisplayOnly += data.OrgList[index].Region + "\r\n";
                                    }
                                    if (data.OrgList[index].Postcode !== undefined && data.OrgList[index].Postcode !== "") {
                                        schoolAddressDisplayOnly += data.OrgList[index].Postcode;
                                    }
                                    $("#SchoolAddressDisplayOnly").val(schoolAddressDisplayOnly);
                                    $("#schoolCombinedAddress").show();
                                    $("input[name='ShowCombinedSchoolAddress']").val(true);
                                    $("#userFields").show();
                                }
                            });
                    } else {
                        $("input[name='OrganisationSchoolName']").val("");
                        $("input[name='AddressLine1']").val(data.Address.OrgName);
                        $("input[name='AddressLine2']").val(data.Address.DefaultAddress.Town);
                        $("input[name='AddressLine3']").val(data.Address.DefaultAddress.Region);
                        $("input[name='Postcode']").val(data.Address.DefaultAddress.Postcode);
                        $("#Country").val(data.Address.DefaultAddress.CountryId.toString());
                        $("#hiddenCountry").val(data.Address.DefaultAddress.CountryId.toString());
                        $("#billingAddressFields").show();
                        $("input[name='ShowBillingAddressFields']").val(true);
                        $("#userFields").show();
                    }
                }
            },
            error: function (xhr, status, error) {
                console.log(xhr.responseText);
                if (isShippingAddress) {
                    $("ShipToAddressResult_Error").show(500);
                    $("input[name='ShipToOrganisationSchoolName']").val("");
                    $("input[name='ShipToAddressLine1']").val("");
                    $("input[name='ShipToAddressLine2']").val("");
                    $("input[name='ShipToAddressLine3']").val("");
                    $("input[name='ShipToPostcode']").val("");
                    $("#ShipToCountry").val("");
                } else {
                    $("#AddressResult_Error").show(500);
                    $("input[name='OrganisationSchoolName']").val("");
                    $("input[name='AddressLine1']").val("");
                    $("input[name='AddressLine2']").val("");
                    $("input[name='AddressLine3']").val("");
                    $("input[name='Postcode']").val("");
                    $("#Country").val("");
                    $("#hiddenCountry").val("");
                }
                alert("message : \n" +
                    "An error occurred, for more info check the js console" +
                    "\n status : \n" + status + " \n error : \n" + error);
            }
        });
    }
    ,
    CheckSearchKeyDown: function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            //Enter Key has been pressed
            $("#AddressSearchButton").click();
        }
    }
    ,
    CheckShippingSearchKeyDown: function(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            //Enter Key has been pressed
            $("#ShippingAddressSearchButton").click();
        }
    }
}

$(document).ready(function () {
    //Set up popovers
    $('[data-toggle="popover"]').popover();
})