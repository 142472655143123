var TECL_Master = {

    RunFreeTextSearch: function () {
        var searchText = $("#freeTextSearch").val();
        var url = "/umbraco/Surface/SearchResultsSurface/RunFreeTextSearch";
        if (searchText !== "") {
            var searchUrl = "/searchresults";
            window.location.href = "/umbraco/Surface/SearchResultsSurface/RunFreeTextSearch?searchText=" +
                searchText +
                "&searchUrl=" +
                searchUrl;
        }
    }
    ,
    CheckFreeTextKeyDown: function (e) {
        if (e.keyCode === 13) {
            //Enter Key has been pressed
            TECL_Master.RunFreeTextSearch();
        }
    },

    /*-------------------------------------------------------------------------------------
    The following JavaScript functions are for locking operations to prevent double clicking
    --------------------------------------------------------------------------------------*/

    // Attempt to add a lock to a key, if the key is already locked then return False, otherwise, add the lock and return True
    AddLock: function (key) {

        var lockCreated = false;

        if (key) {

            // Get the existing list of locks
            var locks;
            try {
                locks = JSON.parse($("#sysItemLocking").val());
            } catch (e) {
                locks = {};
            }

            if (locks && !locks[key]) {
                // This key has not been locked so add it to the list of locks and return true
                locks[key] = true;
                $("#sysItemLocking").val(JSON.stringify(locks));
                lockCreated = true;
            }
        }

        return lockCreated;
    },

    // Remove the lock for key
    ClearLock: function (key) {

        if (key) {
            // Get the existing list of locks
            var locks;
            try {
                locks = JSON.parse($("#sysItemLocking").val());
            } catch (e) {
                locks = {};
            }

            // Delete the lock if required
            if (locks && locks[key]) {
                delete locks[key];
                $("#sysItemLocking").val(JSON.stringify(locks));
            }
        }
    },

    // Check if the text is an email address
    IsEmailAddress: function (emailAddressText) {
        var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,10})?$/;
        var isEmailAddress = emailReg.test(emailAddressText);
        return isEmailAddress;
    }
}