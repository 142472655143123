
var TECL_Home = {

    ToggleLocationAlert: function () {
        $("#locationAlertModal").modal("toggle");
    }
    ,
    DetermineLocation: function () {
        var gvmCountry = $("#gvmCountry").val().toLowerCase();
        var siteDomain = $("#siteDomain").val().toLowerCase();
        var noReminder = TECL_Home.ReadCookie("GL_NO_LOCATION_REMINDER");
        if (noReminder !== "true") {
            if (siteDomain === "glstaginguk.education.co.uk") {
                if (gvmCountry !== "united kingdom") {
                    if (gvmCountry === "republic of ireland") {
                        $(".siteName").text("Republic of Ireland");
                    } else {
                        $(".siteName").text("International");
                    }
                    TECL_Home.ToggleLocationAlert();
                }
            }
            if (siteDomain === "glstagingroi.education.co.uk") {
                if (gvmCountry !== "republic of ireland") {
                    if (gvmCountry === "united kingdom") {
                        $(".siteName").text("UK");
                    } else {
                        $(".siteName").text("International");
                    }
                    TECL_Home.ToggleLocationAlert();
                }
            }
            if (siteDomain === "glstaginginternational.education.co.uk") {
                if (gvmCountry === "united kingdom" || gvmCountry === "republic of ireland") {
                    if (gvmCountry === "republic of ireland") {
                        $(".siteName").text("Republic of Ireland");
                    } else {
                        $(".siteName").text("UK");
                    }
                    TECL_Home.ToggleLocationAlert();
                }
            }
        }
    }
    ,
    Redirect: function() {
        var gvmCountry = $("#gvmCountry").val().toLowerCase();
        var siteDomain = $("#siteDomain").val().toLowerCase();
        if (siteDomain === "glstaginguk.education.co.uk") {
            if (gvmCountry === "republic of ireland") {
                window.location.replace("https://glstagingroi.education.co.uk");
            } else {
                window.location.replace("https://glstaginginternational.education.co.uk");
            }

        }
        if (siteDomain === "glstagingroi.education.co.uk") {
            if (gvmCountry === "united kingdom") {
                window.location.replace("https://glstaginguk.education.co.uk");
            } else {
                window.location.replace("https://glstaginginternational.education.co.uk");
            }
        }
        if (siteDomain === "glstaginginternational.education.co.uk") {
            if (gvmCountry === "united kingdom") {
                window.location.replace("https://glstaginguk.education.co.uk");
            } else {
                window.location.replace("https://glstagingroi.education.co.uk");
            }
        }
    }
    ,
    NoReminder: function() {
        TECL_Home.CreateCookie("GL_NO_LOCATION_REMINDER", true, 30);
    }
    ,
    CreateCookie: function(name, value, days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = "; expires=" + date.toGMTString();
        }
        else var expires = "";
        document.cookie = name + "=" + value + expires + "; path=/";
    }
    ,
    ReadCookie: function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    ,
    EraseCookie: function(name) {
        createCookie(name, "", -1);
    }
}

$(document).ready(function() {
    TECL_Home.DetermineLocation();
});